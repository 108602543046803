import axios from 'axios'
import router from './../router'
import qs from 'qs'
import store from 'vuex'
import http from './http'
/**
 * @Description: axios配置
 */

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
// const pending = new Map()
/**
 * 添加请求
 * 
 @param {Object}
  config
 */
// const addPending = (config) => {
// 	const url = [
// 		config.method,
// 		config.url,
// 		qs.stringify(config.params),
// 		qs.stringify(config.data)
// 	].join('&')
// 	if (config.method === 'get') {
// 		config.paramsSerializer = function(params) {
// 			return qs.stringify(params, {
// 				arrayFormat: 'repeat'
// 			})
// 		}
// 	}

// 	config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
// 		if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
// 			pending.set(url, cancel)
// 		}
// 	})

// }
/**
 * 移除请求
 * @param {Object} config
 */
// const removePending = (config) => {
// 	const url = [
// 		config.method,
// 		config.url,
// 		qs.stringify(config.params),
// 		qs.stringify(config.data)
// 	].join('&')
// 	if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
// 		const cancel = pending.get(url)
// 		cancel(url)
// 		pending.delete(url)
// 	}

// }
/**
 * @Description: 创建axios实例
 */

export default {
	get(url, params) {
		return http({
			method: 'get',
			url,
			params,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json;charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem("token"),
			}
		}).then()
	},
	post(url, params) {
		return http({
			method: 'post',
			url,
			data: params,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json;charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem("token"),
			}
		}).then()
	},
	delete(url, params) {
		return http({
			method: 'delete',
			url,
			data: qs.stringify(params, {
					arrayFormat: 'repeat'
				}),
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json;charset=UTF-8',
				'Authorization': "Bearer " + localStorage.getItem("token"),
			}
		}).then()
	}
}

import http from "../index.js"

// 会员
export function getVip(params) {
	const url = "/Mart/Member/GetsVIPConfig"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}
// 微信二维码
export function getWeixinUrl(params) {
	const url = "/Mart/Member/GetVIPPayUrl"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}
// 
export function GetUserVIPInfo(params) {
	const url = "/Mart/Member/GetUserVIPInfo"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}



export function authentication(params) {
	const url = "/Mart/Company/PostCompany"
	return http
		.post(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}


// 公司详情
export function GetCompany(params) {
	const url = "/Mart/Company/GetCompany"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}
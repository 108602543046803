import http from '../index.js'

// 注册发送短信
export function RegisterSMS(params) {
	const url = '/Mart/MartAccount/SendRegisterSMS'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}

// 注册
export function Register(params) {
	const url = '/Mart/MartAccount/Register'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}

// 登录发送短信
export function loginSMS(params) {
	const url = '/Mart/MartAccount/SendLoginSMS'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}

//登录验证 
export function loginSMSInfo(params) {
	const url = '/Mart/MartAccount/FastLogin'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}


// 获取行业
export function getIndustry(params) {
	const url = '/Mart/MartAccount/GetIndustryCascader'
	return http.get(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 获取职业
export function getOccupation(params) {
	const url = '/Mart/MartAccount/GetOccupationCascader'
	return http.get(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 登录
export function Login(params) {
	const url = '/Mart/MartAccount/Login'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 获取个人信息
export function GetInfoAll(params) {
	const url = '/Mart/MartAccount/GetInfo'
	return http.get(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}

// 设置个人信息
export function SetInfo(params) {
	const url = '/Mart/MartAccount/SetWXInfo'
	return http.post(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 首页轮播图
export function bannerImage(params) {
	const url = '/Mart/Home/GetsBanner'
	return http.get(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 首页热门搜索
export function getHotSearch(params) {
	const url = '/Mart/Home/GetsSearchWork'
	return http.get(url, params).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 首页筛选下拉框
export function getFiltrate(CID) {
	const url = `/Mart/Product/GetsCategoryByID?CID=${CID}`
	return http.get(url, CID).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}

// 首页筛选选择
export function getFiltrateAll(parmas) {
	const url = '/Mart/Product/GetsFilterAttribute'
	return http.get(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 属性筛选列表
export function postQuery(parmas) {
	const url = '/Mart/Product/QueryProducts'
	return http.post(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
//首页客服意见
export function postSuggest(parmas) {
	const url = '/Mart/Home/PostAdvice'
	return http.post(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 修改密码
export function postChangePassword(parmas) {
	const url = '/Mart/MartAccount/ChangePassword'
	return http.post(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 忘记密码短信
export function postForgetSMS(parmas) {
	const url = '/Mart/MartAccount/FindPassWordSMS'
	return http.post(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
// 忘记密码修改
export function postForgetPassword(parmas) {
	const url = '/Mart/MartAccount/UpdateFindPassword'
	return http.post(url, parmas).then((res) => {
		return Promise.resolve(res.data)
	}).catch((err) => {
		return Promise.reject(err.data)
	})
}
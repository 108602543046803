<template>
  <div class="classify" @mouseleave="mouseleave">
    <div class="menu">
      <div class="left-menu">
        <li
          v-for="(item,index) in dataList"
          :key="index"
          @click="switchContent(item,index)"
          :class="active==index?'active':''"
        >
          <span>{{item.label}}</span>
          <img src="../static/image/rightArrows.png" alt="图片错误" />
        </li>
      </div>
      <div class="right-content" :class="scrollHeight<rightHeight?'scrollBar':''">
        <div class="fixedTop">
          <div class="topView">
            <swiper
              :options="swiperOption"
              ref="mySwiper"
              style="text-align: left;padding-bottom: 0.073rem;margin: 0;width: 100%;"
            >
              <swiper-slide
                style="width: auto;margin-right: 20px;"
                v-for="(item,index) in titleList"
                :key="index"
              >
                <span
                  class="topLi"
                  :class="activeTop==index?'activeTop':''"
                  @click="secondMenu(item,index)"
                >{{item.label}}</span>
              </swiper-slide>
            </swiper>
            <p class="more">
              <span>更多</span>
            </p>
          </div>
        </div>
        <div class="dataAll">
          <li
            v-for="(item,index) in contentList"
            :key="index"
            @click="rightContentBtn(item,index)"
            :class="activeContent==index?'activeContent':''"
          >{{item.label}}</li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productCategory } from "@/request/api/upload";
import { postQuery } from "@/request/api/home.js";

export default {
  props: {
    showClassify: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      dataList: [],
      titleList: [],
      contentList: [],
      active: 0, //左侧菜单
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 50
      },
      activeTop: 0, //右侧头部
      activeContent: null, //右侧内容
      scrollHeight: 0, //dataAll内容高度
      rightHeight: 0, //right-content内容高度
      CategoryIDs: [],
      leftClassify: "",
      topClassify: "",
      cententClassify: ""
    };
  },
  mounted() {
    this.getClassification();
  },
  methods: {
    // 分类级联选择器
    getClassification() {
      productCategory().then(res => {
        if (res.Code === 200) {
          this.dataList = res.Data;
          if (this.dataList.length > 0) {
            this.titleList = this.dataList[0].children;
            this.contentList = this.titleList[0].children;
            this.leftClassify = this.dataList[0].value;
            this.topClassify = this.dataList[0].children[0].value;
          }
        }
      });
    },
    // 左侧菜单
    switchContent(item, index) {
      this.active = index;
      this.activeContent = null;
      this.leftClassify = item.value;
      if (!item.children) {
        this.titleList = [];
        this.contentList = [];
      } else {
        this.titleList = item.children;
        this.contentList = this.titleList[0].children;
      }
    },
    // 右侧头部
    secondMenu(item, index) {
      this.activeTop = index;
      this.contentList = item.children;
      this.topClassify = item.value;
    },
    // 右侧内容
    rightContentBtn(item, index) {
      this.activeContent = index;
      this.cententClassify = item.value;
      let parmas = {
        CategoryIDs: [
          this.leftClassify,
          this.topClassify,
          this.cententClassify
        ],
        AttributeFilter: [],
        Sort: 0,
        SolverFilter: []
      };

      postQuery(parmas).then(res => {
        if (res.Code === 200) {
          this.$router.push({
            path: "/productClassify"
          });
          this.$store.commit("saveTopActive", 1);
          localStorage.setItem("topActive", 1);
          localStorage.setItem("parameterName", JSON.stringify(parmas));
          this.$store.commit("saveClassifyData", parmas);
          this.$bus.emit("goodList", res.Data);
          if (this.$route.path == "/productClassify") {
            this.$router.go(0);
          }
        }
      });

      // this.$bus.emit("parameterName", parmas)
    },
    //移除
    mouseleave() {
      this.$emit("mouseleaveView");
    }
  }
};
</script>

<style scoped lang="scss">
.classify {
  z-index: 99;
  position: absolute;
  top: 0.365rem;
  left: 32.5%;
  height: 2.406rem;
  min-width: 880px;

  .menu {
    overflow: hidden;

    .left-menu::-webkit-scrollbar,
    .news_div1::-webkit-scrollbar {
      width: 0 !important;
    }

    .left-menu::-webkit-scrollbar,
    .news_div1::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }

    .left-menu {
      // min-width: 169px;
      background-color: #b4b8c2;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.073rem;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 0.88rem;

      li {
        display: flex;
        justify-content: space-between;
        padding: 0.047rem 0.068rem 0.047rem 0.167rem;
        color: #fff;
      }

      li:hover {
        background-color: #0088ff;
      }

      li:nth-child(1) {
        margin-top: 0.12rem;
      }

      .active {
        background-color: #0088ff;
      }
    }

    .scrollBar {
      overflow-y: hidden !important;
    }

    .right-content {
      cursor: pointer;
      background-color: #fff;
      width: 3.182rem;
      min-width: 611px;
      // height: 2.406rem;
      position: absolute;
      left: 0.88rem;
      padding: 0 0.109rem 0 0.286rem;
      font-size: 0.073rem;
      overflow-x: hidden;
      overflow-y: scroll;
      overflow: auto;
      height: 100%;
      overflow: overlay;

      .fixedTop {
        position: sticky;
        top: 0;
        background: #fff;
        padding: 0.145rem 0.109rem 0 0;
        font-weight: bold;
        // position: relative;
        position: sticky;

        .topActive >>> .swiper-container {
          margin: none !important;
        }

        .topActive ::v-deep .swiper-wrapper {
          // width: 10% !important;
        }

        .topView {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(112, 112, 112, 0.3);
          // position: fixed;
          // top: 0;
          // left: 50%;

          top: 0;

          .topLi {
            padding-bottom: 0.052rem;
          }

          .activeTop {
            border-bottom: 0.026rem solid #0088ff;
          }

          .more {
            white-space: pre;

            span {
              font-weight: normal;
              color: #666666;
              box-shadow: -9px 0px 5px -7px rgb(0 0 0 / 42%);
              padding: 6px 10px 6px 10px;
            }
          }
        }
      }

      .dataAll {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.13rem;

        // padding-top: 0.167rem ;
        li {
          width: 50%;
          text-align: left;
          margin-top: 0.156rem;
        }

        .activeContent {
          color: #0088ff;
        }
      }
    }
  }
}
</style>

import Vue from "vue"
import App from "./App.vue"

// 1rem比192px比例
import "../src/utils/flexible.js"
// import login from "../src/utils/login.js";// 登录弹窗全局组件

// Vue.prototype.$login = login.install

import VueLazyload from 'vue-lazyload'  // 引入这个懒加载插件
Vue.use(VueLazyload, { // 添加自定义选项
  preLoad: 1.3,
  // error: '../static/icon/white.png', // 加载错误时候的图片
  // loading: '../static/icon/white.png', // 加载中的图片
  attempt: 7,
  listenEvents: [ 'scroll' ],
})

import VueBus from 'vue-bus';
Vue.use(VueBus);

import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import axios from "axios"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import VueAwesomeSwiper from "vue-awesome-swiper"
Vue.use(VueAwesomeSwiper);
import "swiper/dist/css/swiper.css";
// 引入通用scss文件初始化样式
import '@/assets/styles/index.scss'
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")

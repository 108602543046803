<template>
  <div class="login" @click="close($event)" @touchmove.prevent>
    <div class="login-conter" @click="stopClose($event)">
      <div class="login_left">
        <img src="../static/image/login.png" alt />
      </div>
      <div class="login_right">
        <div class="title">
          <p>欢迎登录</p>
          <p>
            <img src="../static/image/loginQR.png" alt />
          </p>
        </div>
        <div class="form">
          <div class="formTitle">
            <div
              class="phoneEmil"
              v-for="(item, index) in phoneEmilList"
              @click.stop="switchover(index)"
              :key="index"
              :class="active == index ? 'active' : ''"
            >{{ item }}</div>
          </div>
          <div class="formItem">
            <template v-if="active === 0">
              <div class="phoneNum">
                <div class="phoneInput">
                  <div class="area">
                    <p>+86</p>
                  </div>
                  <el-input
                    v-model="formData.phone"
                    placeholder="手机号"
                    @blur.stop="blurPhone"
                    clearable
                  ></el-input>
                </div>
                <p
                  style="text-align: left; color: red; position: absolute"
                  v-if="showPhone"
                >{{ messagePhone }}</p>
              </div>
              <div class="codeNum">
                <div class="codeInput">
                  <el-input v-model="formData.code" placeholder="验证码" @blur="blurCode" clearable></el-input>
                  <div class="gain" @click.stop="gainCode">
                    <p>{{ !countNum ? btnMsg : countNum + "s" + "重新获取" }}</p>
                  </div>
                </div>
                <p style="text-align: left; color: red" v-if="showCode">{{ messageCode }}</p>
              </div>
            </template>

            <template v-if="active === 1">
              <div class="passwordInput">
                <el-input
                  v-model="passwordLogin.Identity"
                  placeholder="手机号/邮箱/用户名"
                  @blur.stop="blurPhone"
                  clearable
                ></el-input>
              </div>

              <div class="codeNum" :style="{ marginBottom: active === 1 ? '0' : '0.307rem' }">
                <div class="codeInput">
                  <el-input
                    v-model="passwordLogin.Password"
                    placeholder="请输入密码"
                    show-password
                    clearable
                  ></el-input>
                </div>
                <p style="text-align: left; color: red" v-if="passwordShow">{{ passwordError }}</p>
              </div>
              <div class="forget" v-if="active === 1">
                <span @click="forgetPassword" style="cursor: pointer">忘记密码?</span>
              </div>
            </template>

            <div class="submit" @click="submitBtn">
              <p>登录</p>
            </div>
            <div class="goRegister" @click="registerClick">没有账号？去注册</div>
            <div class="privacyBox">
              <div class="pitch" @click="checkPrivacy">
                <img v-if="!affirm" src="../static/image/yuan.png" alt />
                <img v-else src="../static/image/yuan1.png" alt />
              </div>
              <div class="privacy">
                <span>同意平台</span>
                <span
                  style="cursor: pointer"
                  @click="deal(item)"
                  v-for="(item, index) in clause"
                  :key="index"
                >{{ item.text }}</span>
              </div>
            </div>
            <div class="message" style="color: red; text-align: left" v-if="showMessage">请勾选并同意隐私政策</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RegisterSMS,
  Login,
  GetInfoAll,
  loginSMS,
  loginSMSInfo
} from "@/request/api/home";
import { GetUserVIPInfo } from "@/request/api/member.js";
import { getExplain } from "@/request/api/article";
export default {
  props: {
    showLogin: {
      type: Boolean,
      default: () => false
    }
    // // 是否登录
    // showImage: {
    // 	type: Boolean,
    // 	default: () => false
    // },
    // userInfo:{
    // 	type:Object,
    // 	default:()=>{}
    // }
  },
  data() {
    return {
      phoneEmilList: ["手机号登录", "密码登录"],
      active: 0,

      showPhone: false, //手机号message
      messagePhone: "请输入正确的手机号",
      showCode: false, //验证码message
      messageCode: "请输入正确得验证码",
      // 密码登录
      passwordLogin: {
        Identity: null,
        Password: null
      },

      // 手机号登录
      formData: {
        phone: null,
        code: null
      },

      btnMsg: "获取短信验证码",
      countFlag: false,
      countNum: 0, // 倒计时周期
      intervalBtn: {}, //定时器

      affirm: false, //勾选隐私
      showMessage: false, //显隐隐私message

      passwordShow: false,
      passwordError: "",

      userInfo: {},
      isLogin: false,

      clause: [
        {
          id: 5,
          text: "《服务条款》"
        },
        {
          id: 1,
          text: "《隐私政策》"
        },
        {
          id: 6,
          text: "《支付用户服务协议》"
        }
      ]
    };
  },
  created() {
    // this.submitBtn()
  },
  methods: {
    // 登录切换
    switchover(index) {
      this.active = index;
    },
    checkPrivacy() {
      this.affirm = !this.affirm;
      this.affirm ? !this.showMessage : this.showMessage;
    },
    close() {
      this.$emit("close");
    },
    stopClose(e) {
      e.stopPropagation();
    },
    // 手机号输入
    blurPhone(val) {
      let del = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!del.test(this.formData.phone)) {
        this.showPhone = true;
      } else {
        this.showPhone = false;
      }
    },
    blurCode(val) {
      let code = /^\d{4}$/;
      this.formData.code == val;
      // if(!code.test(this.formData))
    },
    // 获取验证码
    gainCode() {
      let del = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!del.test(this.formData.phone)) {
        this.showPhone = true;
        return false;
      } else {
        if (this.countNum > 0) {
          this.$message({
            message: "请勿重复获取",
            duration: 1000
          });
          return false;
        } else {
          this.countNum = 60;
          let params = {
            PhoneNumber: this.formData.phone
          };
          loginSMS(params).then(res => {
            if (res.Code === 200) {
            }
          });
        }
        this.intervalBtn = setInterval(() => {
          this.countNum--;
          if (this.countNum < 1) {
            clearInterval(this.intervalBtn);
            this.countNum = 0;
          }
        }, 1000);
      }
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push({
        path: "/resetPasswords"
      });
    },
    login() {},
    // 登录
    submitBtn() {
      if (this.active === 1) {
        if (!this.affirm) {
          this.showMessage = true;
          return false;
        } else {
          Login(this.passwordLogin).then(res => {
            if (res.Code === 200) {
              localStorage.setItem("token", res.Data.access_token);
              GetUserVIPInfo().then(res => {
                localStorage.setItem("UserVIPInfo", JSON.stringify(res.Data));
              });
              GetInfoAll().then(res => {
                if (res.Code === 200) {
                  localStorage.setItem("userInfo", JSON.stringify(res.Data));
                  localStorage.setItem("isLogin", true);
                  this.$store.commit("saveIsLogin", true);
                  this.$emit("submitBtn");
                }
              });
            } else {
              this.passwordShow = true;
              this.passwordError = res.Error.Message;
            }
          });
        }
      } else {
        if (!this.affirm) {
          this.showMessage = true;
          return false;
        } else {
          let params = {
            PhoneNumber: this.formData.phone,
            PhoneCode: this.formData.code
          };
          loginSMSInfo(params).then(res => {
            if (res.Code === 200) {
              localStorage.setItem("token", res.Data.access_token);
              GetInfoAll().then(res => {
                if (res.Code === 200) {
                  localStorage.setItem("userInfo", JSON.stringify(res.Data));
                  localStorage.setItem("isLogin", true);
                  this.$store.commit("saveIsLogin", true);
                  this.$emit("submitBtn");
                }
              });
            } else {
              this.passwordShow = true;
              this.passwordError = res.Error.Message;
            }
          });
        }
      }
    },
    //注册
    registerClick() {
      this.$router.push({
        path: "/register",
        query: {
          type: 1
        }
      });
    },
    deal(item) {
      getExplain(item.id).then(res => {
        if (res.Code === 200) {
          this.$alert(res.Data.Content, res.Data.Title, {
            dangerouslyUseHTMLString: true,
            customClass: "customClass"
          });
        }
      });
    }
  }
};
</script>

<style>
.login .phoneInput .el-input__inner {
  border: none;
  height: 0.234rem;
}

.customClass {
  width: 3.385rem;
  max-height: 650px !important;
  overflow-y: scroll !important;
}
</style>
<style scoped lang="scss">
.login {
  z-index: 99;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;

  .login-conter {
    width: 6.161rem;
    height: 3.109rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .login_left {
      width: 3.089rem;
      height: 3.109rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .login_right {
      background-color: #fff;
      width: 3.073rem;
      height: 100%;

      .title {
        font-size: 0.125rem;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        /* margin: 0 auto; */
        margin-left: 0.401rem;
        margin-right: 0.104rem;

        p:nth-child(1) {
          // position: relative;
          // left: 0.401rem;
          // top: 0.354rem;
          margin-top: 0.354rem;
          letter-spacing: 3px;
          margin-bottom: 0.208rem;
        }

        p:nth-child(2) {
          width: 0.38rem;
          height: 0.38rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .form {
        margin: 0 auto;
        // margin-top: 0.734rem;
        margin-left: 0.401rem;
        margin-right: 0.51rem;
        font-size: 0.073rem;
        margin-bottom: 37px;

        .formTitle {
          display: flex;
          justify-content: left;
          align-items: center;
          border-bottom: 1px solid rgba(112, 112, 112, 0.1);
          margin-bottom: 0.208rem;

          .phoneEmil:hover {
            cursor: pointer;
          }

          .phoneEmil {
            padding-bottom: 0.031rem;
            letter-spacing: 1.5px;
            font-size: 0.073rem;
            margin-right: 0.26rem;
          }

          .active {
            font-size: 0.094rem;
            color: #0088ff;
            border-bottom: 2px solid #0088ff;
          }
        }

        .formItem {
          .passwordInput {
            display: flex;
            align-items: center;
            // margin-top: 0.104rem;
            height: 0.234rem;
          }

          .passwordInput >>> .el-input {
            height: 100%;
          }

          .passwordInput >>> .el-input__inner {
            padding-left: 0.104rem;
            font-size: 0.073rem;
            height: 100%;
          }

          .phoneNum {
            .phoneInput {
              display: flex;
              align-items: center;
              border: 1px solid #dcdfe6;
              border-radius: 4px;
              height: 0.234rem;

              .area {
                // padding: 0.068rem 0;

                p {
                  color: #666666;
                  padding: 0 0.094rem 0 0.104rem;
                  border-right: 1px solid rgba(112, 112, 112, 0.2);
                }
              }
            }
          }

          .phoneNum >>> .el-input {
            height: 100%;
            margin: 0;
          }

          .phoneNum >>> .el-input__inner {
            border: none;
            // height: 0.188rem;
            padding-left: 0.104rem;
            font-size: 0.073rem;
            height: 100%;
          }

          .codeNum {
            margin-bottom: 0.302rem;

            .codeInput {
              display: flex;
              align-items: center;
              margin-top: 0.104rem;
              height: 0.234rem;

              .gain {
                border: 1px solid rgba(26, 26, 26, 0.2);
                white-space: pre;
                margin-left: 0.104rem;
                background-color: #0088ff;
                color: #fff;
                border-radius: 4px;
                font-size: 0.073rem;

                p {
                  padding: 0.063rem 0.104rem;
                }
              }
            }
          }

          .codeNum >>> .el-input {
            height: 100%;
            margin: 0;
          }

          .codeNum >>> .el-input--suffix {
            height: 0.234rem;
          }

          .codeNum >>> .el-input__inner {
            border: 1px solid rgba(26, 26, 26, 0.2);
            padding-left: 0.104rem;
            font-size: 0.073rem;
            height: 100%;
          }

          .forget {
            margin-bottom: 0.135rem;
            text-align: right;
            margin-top: 0.068rem;
            color: #222222;
          }

          .submit:hover {
            cursor: pointer;
          }

          .submit {
            background-color: #0088ff;
            width: 0.896rem;
            text-align: center;
            margin-bottom: 0.135rem;
            border-radius: 4px;

            p {
              padding: 0.099rem 0;
              font-size: 0.068rem;
              color: #fff;
            }
          }

          .goRegister:hover {
            cursor: pointer;
          }

          .goRegister {
            width: 0.63rem;
            color: #0088ff;
            font-size: 0.073rem;
            text-align: left;
            margin-bottom: 0.207rem;
          }

          .privacyBox {
            display: flex;
            align-items: center;
            color: #a0a2a4;

            .pitch {
              display: flex;
              margin-right: 0.083rem;
            }
          }

          .backColor {
            background-color: #0088ff;
            // border: 2px solid rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
</style>

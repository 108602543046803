import store from "@/store"
import axios from "axios"
import qs from "qs"
import { Message } from "element-ui"
// 创建axios实例
const http = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: window.baseUrl || process.env.VUE_APP_API_URL,
  // 超时
  timeout: 100000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
})
http.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      // console.log('请求头加入token')
      config.headers.token = localStorage.getItem("token")
    }

    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat",
        })
      }
    } else if (config.method === "delete") {
      config.paramsSerializer = function (params) {
        // console.log(qs.stringify(params, {
        // 	arrayFormat: 'repeat'
        // }));

        return qs.stringify(params, {
          arrayFormat: "repeat",
        })
      }
    }

    return config
  },

  (error) => {
    console.log("请求失败！！")
    console.log(error)
    return Promise.reject(error)
  }
)

//异步请求后，判断token是否过期
http.interceptors.response.use(
  (response) => {
    if (response.data.Code === 401) {
      store.commit("saveShowLogin", true)
      localStorage.clear()
    }
    if (response.data.Code === 422) {
      Message.error(response.data.Error.Message || "请求错误")
    }
    return Promise.resolve(response)
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("token")
          this.$router.push("/")
      }
    }
  }
)
export default http

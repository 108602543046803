import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showLogin: false,
		token: '',
		userInfo: localStorage.getItem('userInfo') || {},
		isLogin: localStorage.getItem('isLogin'),
		topActive:localStorage.getItem("topActive"),
		Classify:false,
		Materials:false,
		Type:false,
		seekShow:false,//显隐搜索下拉
		classifyData:localStorage.getItem('parameterName') || {},//储存分类
	},
	getters: {
		getShowLogin(state) {
			return state.showLogin
		},
	},
	mutations: {
		// 显隐登录
		saveShowLogin: (state, res) => {
			state.showLogin = res
		},
		// 是否登录
		saveIsLogin: (state, res) => {
			state.isLogin = res
		},
		// 顶部选择
		saveTopActive:(state,res)=>{
			state.topActive=res
		},
		// 首页分类下拉框显隐
		saveClassify:(state,res)=>{
			state.Classify=res
		},
		// 首页材料下拉框显隐
		saveMaterials:(state,res)=>{
			state.Materials=res
		},
		// 首页型号下拉框显隐
		saveType:(state,res)=>{
			state.Type=res
		},
		saveUserInfo:(state,res)=>{
			state.userInfo=res
		},
		saveSeek:(state,res)=>{
			state.seekShow=res
		},
		saveClassifyData:(state,res)=>{
			state.classifyData=res
		}

	},
	actions: {},
	modules: {}
})


const constantRoutes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/Home.vue"),
    meta: {
      title: "材料卡网",
      showLogin: false,
    },
  },
  // 全部分类
  {
    path: "/productClassify",
    name: "productClassify",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/productClassify/index.vue"
      ),
    meta: {
      title: "全部分类",
      showLogin: false,
    },
  },
  // 新闻资讯
  {
    path: "/newsInformation",
    name: "newsInformation",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/newsInformation/index.vue"
      ),
    meta: {
      title: "新闻资讯",
      showLogin: false,
    },
  },
  // 新闻资讯详情
  {
    path: "/newsInformationDetail",
    name: "newsInformationDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/newsInformationDetail/index.vue"
      ),
    meta: {
      title: "新闻资讯详情",
      showLogin: false,
    },
  },
  // 商品详情
  {
    path: "/shoppingDetail",
    name: "shoppingDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/shoppingDetail/index.vue"
      ),
    meta: {
      title: "商品详情",
      showLogin: false,
    },
  },
  // 商品搜索
  {
    path: "/seachDetail",
    name: "seachDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/seachDetail/index.vue"
      ),
    meta: {
      title: "商品详情",
      showLogin: false,
    },
  },
  // 会员中心
  {
    path: "/menberCenter",
    name: "menberCenter",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/menberCenter/index.vue"
      ),
    meta: {
      title: "会员中心",
      showLogin: false,
    },
  },
  // 个人中心
  {
    path: "/myMessage",
    name: "myMessage",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/myMessage/index.vue"
      ),
    meta: {
      title: "个人中心",
      showLogin: false,
    },
  },
  // 成为企业用户上传材料
  {
    path: "/uploadCertification",
    name: "uploadCertification",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/uploadCertification/index.vue"
      ),
    meta: {
      title: "成为企业用户上传材料",
      showLogin: true,
    },
  },
  // 修改密码
  {
    path: "/changePassword",
    name: "changePassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/changePassword/index.vue"
      ),
    meta: {
      title: "修改密码",
      showLogin: false,
    },
  },
  // 忘记密码
  {
    path: "/resetPasswords",
    name: "resetPasswords",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/resetPasswords/index.vue"
      ),
    meta: {
      title: "忘记密码",
      showLogin: false,
    },
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register/index.vue"),
    meta: {
      title: "注册账号",
      showLogin: false,
    },
  },
  // 商品结算
  {
    path: "/settleAccounts",
    name: "settleAccounts",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/settleAccounts/index.vue"
      ),
    meta: {
      title: "商品结算",
      showLogin: true,
    },
  },
  // 付款
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/payment/index.vue"
      ),
    meta: {
      title: "付款中",
      showLogin: true,
    },
  },
  // 文件上传
  {
    path: "/filesUpload",
    name: "filesUpload",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/filesUpload/index.vue"
      ),
    meta: {
      title: "文件上传",
      showLogin: true,
    },
  },
  // {
  // 	path: "/invoiceDetail",
  // 	name: "invoiceDetail",
  // 	component: () =>
  // 		import(
  // 			/* webpackChunkName: "about" */
  // 			"../views/myMessage/components/invoiceDetail/index.vue"
  // 		),
  // 	meta: {
  // 		title: '文档',
  // 		showLogin: true
  // 	}
  // },
  {
    path: "/myInvoice",
    name: "myInvoice",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/myMessage/components/myInvoice/index.vue"
      ),
    meta: {
      title: "文档",
      showLogin: true,
    },
  },
]
// import {
// 	createRouter,
// } from "vue-router";
// import invoiceDetail from "@/views/myMessage/components/invoiceDetail";
// import myInvoice from "@/views/myMessage/components/myInvoice";
// const router = createRouter({
// 	routes: [{
// 			path: '/invoiceDetail',
// 			component: invoiceDetail,
// 		},
// 		{
// 			path: '/myInvoice',
// 			component: myInvoice,
// 		},
// 	]
// })
export default constantRoutes

import Vue from "vue"
import VueRouter from "vue-router"
import constantRoutes from "../router/router"



import Store from "../store/index"
Vue.use(VueRouter)
const createRouter = () =>
	new VueRouter({
		mode: "history",
		scrollBehavior: () => ({
			y: 0,
		}),
		routes: constantRoutes,
	})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch((err) => err)
}

const router = createRouter()
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	let token = localStorage.getItem("token")
	if (to.path === "/filesUpload") {
		if (!token || token == undefined) {
			Store.commit("saveShowLogin", to.meta.showLogin)
			localStorage.setItem('topActive',0)
			Store.commit('saveTopActive',0 )
			return false
		}
		
	}

	next()
})
router.afterEach((to) => {
	// 设置title


	document.title = to.meta.title
})

export default router

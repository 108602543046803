<template>
	<div id="app" :style="[{ minWidth: !isDeviceMobile() ? '1200px' : 0 }]" @click="packUp">
		<top-Bar v-if="!(path == '/register' || path == '/resetPasswords' )"></top-Bar>
		<router-view />
		<tab-Bar
			v-if="!(path == '/register'||path=='/filesUpload' || path == '/myMessage' || path == '/uploadCertification' || path == '/resetPasswords' || path == '/settleAccounts'||path == '/payment')">
		</tab-Bar>
	</div>
</template>
<script>
	import topBar from "@/components/topBar"
	import tabBar from "@/components/tabBar"
	export default {
		components: {
			topBar,
			tabBar
		},
		data() {
			return {
				path: "",
			}
		},
		watch: {
			$route(to, from) {
				this.path = to.path

			},
		},
		created() {
			this.isDeviceMobile()
			localStorage.setItem('topActive',0)
			// localStorage.removeItem('parameterName')
		},
		onunload(){
			
		},
		
		methods: {
			packUp (){
			this.$store.commit('saveClassify', false)
			this.$store.commit('saveMaterials', false)
			this.$store.commit('saveType', false)	
			},
			isDeviceMobile() {
				//判断函数 返回值true为移动端 false为PC端
				var u = navigator.userAgent
				var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android终端
				var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
				if (isiOS || isAndroid) {
					return true
				} else {
					return false
				}
			},
			beforeunloadHandler(e) {
				// localStorage.setItem('topActive',0)
				
			}
		},
		// 绑定窗口关闭[监听]事件
		mounted() {
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
		},
		// 解除窗口关闭[监听]事件
		destroyed() {
			window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
			
		}
		// methods: {

		// },
	}
</script>

<style lang="scss">
	#app {
		width: 100%;
		min-height: 100vh;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
overflow-x: hidden;
	}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>

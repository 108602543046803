import http from '../index.js'



// 产品分类级联选择
export function productCategory (params) {
    const url = '/Mart/Product/GetCascader'
    return http.get(url, params).then((res) => {
        return Promise.resolve(res.data)
    }).catch((err) => {
        return Promise.reject(err.data)
    })
}
// 上传获取属性
export function GetsAttribute (params) {
    const url = '/Mart/Product/GetsAttribute'
    return http.get(url, params).then((res) => {
        return Promise.resolve(res.data)
    }).catch((err) => {
        return Promise.reject(err.data)
    })
}
// 上传图片
export function uploadImage (params) {
    const url = '/api/Upload/Upload'
    return http.post(url, params).then((res) => {
        return Promise.resolve(res.data)
    }).catch((err) => {
        return Promise.reject(err.data)
    })
}

// 求解器
export function Getsolver (params) {
    const url = '/Mart/Product/GetsFilterSolverSelect'
    return http.get(url, params).then((res) => {
		console.log(params);
        return Promise.resolve(res.data)
    }).catch((err) => {
        return Promise.reject(err.data)
    })
}
// export function Getsolver (params) {
//     const url = '/Mart/Product/GetsSolverSelect'
//     return http.get(url, params).then((res) => {
// 		console.log(params);
//         return Promise.resolve(res.data)
//     }).catch((err) => {
//         return Promise.reject(err.data)
//     })
// }

// 商品上传
export function uploadGoods (params) {
    const url = '/Mart/Product/PostProduct'
    return http.post(url, params).then((res) => {
		console.log(params);
        return Promise.resolve(res.data)
    }).catch((err) => {
        return Promise.reject(err.data)
    })
}
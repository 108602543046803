<template>
  <div id="topBar">
    <div class="title">
      <div class="imgBox">
        <img src="../assets/logo.png" alt="图片错误" />
      </div>
      <div class="switchover">
        <li
          v-for="(item, index) in activeList"
          :key="index"
          @mouseenter="mouseenter(item, index)"
          @click="activeBtn(item, index)"
          :class="$store.state.topActive == index ? 'active' : ''"
        >
          <span>{{ item.text }}</span>
        </li>
      </div>

      <div class="loginBtn">
        <p v-if="!$store.state.isLogin" @click="loginBtn">登录</p>
        <div class="headImg" v-else>
          <template v-if="userInfo">
            <i @click="pushcar"></i>
            <div class="userImage" @click="personal">
              <img :src="userInfo.HeadImageUrl" alt="" />
            </div>
            <span @click="logOut">退出登录</span>
          </template>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 0.365rem"></div>
    <login-Popup
      v-if="$store.state.showLogin"
      :showLogin="$store.state.showLogin"
      @close="close"
      @submitBtn="submitBtn"
    >
    </login-Popup>
    <classify
      :dataList="dataList"
      v-if="showClassify"
      @mouseleaveView="mouseleaveView"
      :showClassify="showClassify"
    ></classify>
  </div>
</template>
<script>
import { productCategory } from "@/request/api/upload"
import loginPopup from "@/components/loginPopup.vue"
import classify from "@/components/classify.vue"
import { mapMutations } from "vuex"
export default {
  name: "topBar",
  components: {
    loginPopup,
    classify,
  },
  data() {
    return {
      activeList: [
        {
          text: "首页",
          path: "/",
        },
        {
          text: "全部分类",
          path: "/productClassify",
        },
        {
          text: "新闻资讯",
          path: "/newsInformation",
        },
        {
          text: "会员中心",
          path: "/menberCenter",
        },
        {
          text: "文件上传",
          path: "/filesUpload",
        },
      ],
      placeholder: "材料科学",
      dataList: [],
      searchList: ["材料", "材料", "材料", "材料", "材料"],

      active: null,
      showLogin: false, //显影登录弹窗
      showClassify: false, //显隐悬浮显示的全部分类
      isLogin: false, //显隐头像,
      userInfo: {},
    }
  },

  created() {},

  mounted() {
    this.getStorage()
    this.getItem()
    this.$nextTick(() => {
      this.showLogin = this.$store.state.showLogin
      this.isLogin = this.$store.state.isLogin
      // this.$store.commit('saveTopActive',)
      this.active = localStorage.getItem("topActive")
      this.getClassification()
    })
  },
  computed: {
    popLogin() {
      return this.$store.state.showLogin
    },
  },
  watch: {
    popLogin: {
      deep: true,
      handler(newVal, oldVal) {
        this.showLogin = newVal
      },
    },
  },

  methods: {
    pushcar(){
      this.$router.push({
        path:"myMessage",
        query:{
          gwc:true
        }
      })
    },
    close() {
      // this.$router.push({
      // 	path: '/',
      // })
      // localStorage.clear()
      this.$store.commit("saveIsLogin", false)
      this.$store.commit("saveShowLogin", false)
    },
    getStorage() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      let isLogin = localStorage.getItem("isLogin")
      if (userInfo) {
        this.userInfo = userInfo
        this.isLogin = isLogin
      }
    },
    // 获取顶部active
    getItem() {
      if (!localStorage.getItem("topActive")) {
        this.active = 0
        return false
      } else {
        this.active = localStorage.getItem("topActive")
      }
    },
    // 点击登录注册按钮
    loginBtn() {
      this.$store.commit("saveShowLogin", true)
    },
    // 登录按钮
    submitBtn() {
      this.$store.commit("saveIsLogin", true)
      this.$store.commit("saveShowLogin", false)
      this.getStorage()
    },
    // 点击头像
    personal() {
      this.$router.push({
        path: "/myMessage",
      })
      localStorage.setItem("topActive", -1)
    },
    logOut() {
      localStorage.clear()
      this.$store.commit("saveIsLogin", false)
      this.$router.push({
        path: "/",
      })
    },
    // topBar的切换
    activeBtn(item, index) {
      this.active = index
      localStorage.setItem("topActive", this.active)
      this.$store.commit("saveTopActive", this.active)
      this.$router.push({
        path: item.path,
      })
      localStorage.removeItem("parameterName")
    },
    // 分类级联选择器
    getClassification() {
      productCategory().then((res) => {
        if (res.Code === 200) {
          this.dataList = res.Data
        }
      })
    },
    // 鼠标移入顶部li
    mouseenter(item, index) {
      if (index == 1) {
        this.showClassify = true
      } else {
        this.showClassify = false
      }
    },

    // 移出组件的显隐
    mouseleaveView(val) {
      this.showClassify = false
    },
  },
}
</script>
<style scoped lang="scss">
#topBar {
  font-size: 0.083rem;
  // position: relative;

  .title {
    width: 100%;
    min-width: 1200px;
    height: 0.365rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    // padding: 0.12rem 0 0.078rem 0;
    background: #fff;
    display: flex;
    align-items: center;

    .imgBox {
      // display: inline-block;
      width: 1.422rem;
      height: 0.208rem;
      margin: 0 0.521rem 0 0.729rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .switchover:hover {
    cursor: pointer;
  }

  .switchover {
    display: flex;
    justify-content: center;
    margin-right: 1.677rem;

    li {
      padding: 0 0.208rem;
      // font-weight: bold;
      font-size: 0.083rem;
    }

    .active {
      color: #0088ff;
    }
  }

  .loginBtn:hover {
    cursor: pointer;
  }

  .loginBtn {
    letter-spacing: 1.5px;

    .headImg {
      // width: 0.208rem;
      height: 100%;
      display: flex;
      align-items: center;
      i{
        width: 20px;
        height: 20px;
        margin-right: 20px;
        background: url("@/static/mine/cart.png") no-repeat 0 0/ 100% 100%;
      }

      .userImage {
        width: 0.208rem;
        height: 0.188rem;
        margin-right: 0.078rem;
        display: flex;
        align-items: center;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .p:hover {
    border-bottom: 1px solid #0088ff;
    color: #0088ff;
  }
}
</style>

import http from "../index.js"

// 动态资讯
export function getByQuery(params) {
	const url = "/Mart/Article/GetsByQuery"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 通知公告
export function getNotices(params) {
	const url = "/Mart/Home/GetNotices"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}
// 平台说明
export function getExplain(type) {
	const url = `Mart/Home/GetUserDom?type=${type}`
	return http
		.get(url, type)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 消息列表
export function getNews(type) {
	const url = "/Mart/Home/GetsMessage"
	return http
		.get(url, type)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 

// 资讯详情
export function getByQueryDetail(ArtialID) {
	const url = `/Mart/Article/Get?ArtialID=${ArtialID}`
	return http
		.post(url, ArtialID)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 系统公告详情
export function getNoticesDetail(id) {
	const url = `/Mart/Home/GetNoticeDetail?id=${id}`
	return http
		.post(url, id)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 会员记录
export function getUserMember(params) {
	const url = "/Mart/Member/GetUserMemberLog"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 申请发票
export function MartPostInvoice(parmas) {
	const url = "/Mart/MartInvoice/PostInvoice"
	return http
		.post(url, parmas)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 省
export function getProvinces(params) {
	const url = "/area/Area/GetProvinces"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 市
export function getCitiesByProvinceID(params) {
	const url = "/area/Area/GetCitiesByProvinceID"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

// 区
export function getDistrictsByCityID(params) {
	const url = "​/area​/Area​/GetDistrictsByCityID"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}
// 发票记录
export function getInvoiceRecord(params) {
	const url = "/Mart/MartInvoice/GetsInvoiceRecord"
	return http
		.get(url, params)
		.then((res) => {
			return Promise.resolve(res.data)
		})
		.catch((err) => {
			return Promise.reject(err.data)
		})
}

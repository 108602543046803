<template>
  <div id="tabBar">
    <div class="foot_logo">
      <div class="logo_left">
        <div class="loge_img">
          <img src="../static/image/logo.png" alt="" />
        </div>
        <div class="logo_tab">
          <div v-for="(item, index) in tabList" :key="index">
            {{ "" }}
          </div>
        </div>
        <div class="logo_phone">
          联系我们:
          <a href="mailto:materials idc@jitri-amrd.com"
            >materials idc@jitri-amrd.com</a
          >
        </div>
        <div class="logo_phone">&nbsp;&nbsp;&nbsp;赵经理: 18516116642</div>
      </div>
      <div class="logo_right">
        <div>
          <img src="../static/image/erwei1.png" alt="" />公众号：数智成材
        </div>
        <div>
          <img src="../static/image/erwei2.png" alt="" />小程序：Alphajoint
        </div>
      </div>
    </div>
    <div class="footter">
      Copyright © /2023-现在 alphamdata.com，All Rights Reserved
      使用本网站即表示接受材料卡网用户协议。版权所有：苏州数材科技有限责任公司。<a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        >苏ICP备2023000453号-1</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "tabBar",
  data() {
    return {
      tabList: [
        { name: "首页", path: "" },
        { name: "产品分类", path: "" },
        { name: "新闻资讯", path: "" },
        { name: "会员中心", path: "" },
        { name: "文件上传", path: "" },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>
<style scoped lang="scss">
#tabBar {
  width: 100%;
  .foot_logo {
    background: #ffffff;
    color: #1a1a1a;
    height: 1.04rem;
    // display: flex;
    // justify-content: space-between;
    // align-content: center;
    padding: 0.208rem 1.354rem 0.104rem;
    box-sizing: border-box;
    .logo_left {
      text-align: left;
      float: left;
      .loge_img {
        width: 1.286rem;
        height: 0.208rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logo_tab {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 0.08rem;
        margin: 0.14rem 0;
        div:nth-child(n + 2) {
          margin: 0 0 0 0.1rem;
        }
      }
      .logo_phone {
        font-size: 0.08rem;
        color: #666666;
      }
    }
    .logo_right {
      float: right;
      display: flex;
      justify-content: space-between;
      align-content: center;
      div:nth-child(n + 2) {
        margin: 0 0 0 0.1rem;
      }
      div {
        width: 0.66rem;
        height: 0.66rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footter {
    width: 100%;
    background: #f7f7f7;
    font-size: 0.07rem;
    color: #666666;
    text-align: center;
    padding: 0.1rem 0;
  }
}
</style>
